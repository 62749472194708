@import "../../variables";

header {
  .menu {
    position: fixed;
    width: 100%;
    height: 1px;
    left: 0;
    top: 0;
    z-index: 30;

    @media (max-width: 768px) {
      position: absolute;
    }

    nav {
      position: absolute;
      left: 0;
      top: 0;
      height: 100vh;
      width: 100%;
      transform: translate3d(0, -100%, 0);
      background: $color-secondary;
      overflow-y: auto;
      padding-bottom: 2rem;
      @include transition-snappy;

      @at-root .menu--open & {
        transition-delay: 0 !important;
        transition-duration: 200ms !important;
      }

      @media (max-width: 992px) {
        overflow-y: scroll;
      }

      @media (max-width: 768px) {
        position: fixed;
        padding-bottom: 3rem;
      }

      .menu-logo {
        position: relative;
        text-align: center;
      }

      .nav_list {
        position: relative;
        padding: 3.5rem 1rem 1rem;
        list-style: none;

        li {
          text-align: center;
        }

        a {
          font-size: 3.5rem;
          line-height: 1.5;
          text-decoration: none;
          font-family: $special-font;
          color: $color-tertiary;

          @media (max-width: 768px) {
            font-size: 2rem;
          }

          &:hover {
            color: #000;
          }
        }

        &--submenu {
          position: relative;
          padding: 2.5rem 1rem 1rem;
          position: relative;

          a {
            font-size: 1.75rem;
            line-height: 1.5;
            text-decoration: none;
            font-family: $basic-font;
            color: $color-tertiary;

            @media (max-width: 768px) {
              font-size: 1.5rem;
            }

            &:hover {
              color: #000;
            }
          }

          &::after {
            content: "";
            display: block;
            position: absolute;
            left: 50%;
            top: 0;
            width: 3rem;
            height: 1px;
            background: $color-tertiary;
            transform: translateX(-50%);
          }
        }

        &--adminitems {
          padding: 1rem;
          position: relative;

          a {
            font-size: 1.75rem;
            line-height: 1.5;
            text-decoration: none;
            font-family: $basic-font;
            color: $color-tertiary;

            @media (max-width: 768px) {
              font-size: 1.5rem;
            }

            &:hover {
              color: #000;
            }
          }
        }
      }
    }

    &__trigger {
      position: absolute;
      right: 50px;
      top: 50px;
      color: $color-tertiary;
      width: 50px;
      height: 50px;
      cursor: pointer;

      @media (max-width: 768px) {
        right: 40px;
        top: 30px;
      }

      &-text {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        transition: 0.3s ease;
        background: $color-primary;
        padding: 0.5rem;
        font-weight: 700;
      }

      &-burger {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        height: 30px;
        transform: translate3d(-50%, -50%, 0);
        opacity: 0;
        transition: 0.3s ease;

        &::before {
          content: "";
          position: absolute;
          left: 50%;
          top: 0;
          width: 100%;
          height: 2px;
          background: $color-tertiary;
          transform: translate3d(-50%, 0, 0);
        }

        &::after {
          content: "";
          position: absolute;
          left: 50%;
          bottom: 0;
          width: 100%;
          height: 2px;
          background: $color-tertiary;
          transform: translate3d(-50%, 0, 0);
        }

        span {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 100%;
          height: 2px;
          background: $color-tertiary;
          transform: translate3d(-50%, -50%, 0);
        }
      }
    }

    &__close {
      position: absolute;
      right: 50px;
      top: 50px;
      width: 50px;
      height: 50px;
      overflow: hidden;
      cursor: pointer;

      @media (max-width: 768px) {
        right: 40px;
        top: 40px;
        width: 30px;
        height: 30px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 200%;
        height: 2px;
        background: $color-tertiary;
        transform: translate3d(-50%, 0, 0) rotate(45deg);
      }

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 200%;
        height: 2px;
        background: $color-tertiary;
        transform: translate3d(-50%, 0, 0) rotate(-45deg);
      }
    }

    &--open {
      nav {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}

.menu__trigger {
  $trigger: &;

  &:hover {
    #{$trigger}-text {
      opacity: 0;
      transform: translate3d(-50%, -50%, 0) scale(0);
    }
    #{$trigger}-burger {
      opacity: 1;
    }
  }
}
